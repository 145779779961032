/*
  A component so safely link to the ListingPage of the given listing.

  When the listing is pending approval, the normal ListingPage won't
  work as the listing isn't yet published. This component links to the
  correct pending-approval variant URL or to the normal ListingPage
  based on the listing state.
*/
import React from 'react';
import { string, func, oneOfType, node, shape } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { richText } from '../../util/richText';
import { injectIntl } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { NamedLink } from '../../components';

import css from './ListingLink.module.css';
import { createResourceLocatorString } from '../../util/routes';

const MIN_LENGTH_FOR_LONG_WORDS = 16;


const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const storeId = listing.attributes.publicData.storeId;
  const storeSlug = createSlug(listing.attributes.publicData.storeName);

  const linkProps = {
    name: 'ListingPage',
    params: { id: storeId, slug: storeSlug },
    hash: `/${id}`
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {}, linkProps.hash);
};



const ListingLink = props => {
  const { className, listing, history, children } = props;
  const listingLoaded = listing && listing.id;
  if (!listingLoaded) {
    return null;
  }
  const id = listing.id.uuid;
  const { title, state, publicData } = listing.attributes;
  const listingType = publicData?.listingType;
  const routeConfiguration = useRouteConfiguration();


  const storeId = listingType === 'daily-booking' && publicData?.storeId;
  const storeTitle = listingType === 'daily-booking' && toString(publicData?.storeTitle);
  const slugStoreTitle = storeTitle && createSlug(storeTitle);

  const slug = createSlug(title);
  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = state === LISTING_STATE_DRAFT;
  const variant = isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : isDraft
      ? LISTING_PAGE_DRAFT_VARIANT
      : null;


  const linkProps = !!variant
    ? {
      name: 'ListingPageVariant',
      params: {
        id,
        slug,
        variant,
      },
    }
    : listingType === 'store-listing' ? {
      name: 'ListingPage',
      params: { id, slug },
    } : listingType === 'brand-listing' ?
      {
        name: 'BrandListingPage',
        params: { id, slug },
      } : listingType === 'product-selling' ?
        {
          name: 'ProductListingPage',
          params: { id, slug },
        } : {
          name: 'ListingPage',
          params: { id, slug },
        };

  // const linkProps = !!variant
  //   ? {
  //     name: 'ListingPageVariant',
  //     params: {
  //       id,
  //       slug,
  //       variant,
  //     },
  //   }
  //   : {
  //     name: 'ListingPage',
  //     params: { id, slug },
  //   };
  return (
    listingType === 'daily-booking' ?
      <div
        className={className}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          history.push(createListingURL(routeConfiguration, listing));
        }}
      >
        {children ? children : richTitle || ''}
      </div>
      :
      <NamedLink className={className} {...linkProps}>
        {children ? children : richTitle || ''}
      </NamedLink>
  );
};
ListingLink.defaultProps = {
  className: null,
  listing: null,
  children: null,
};

ListingLink.propTypes = {
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  children: node,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
export default compose(
  withRouter,
  injectIntl
)(ListingLink);
