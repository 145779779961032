import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

// Utility function to get the first image
export const getFirstImage = (currentListing, listingType) => {
  let orderedImages = [];

  if (listingType === 'store-listing') {
    const storeImagesOrder = currentListing.attributes?.publicData?.storeImagesOrder || [];
    orderedImages = [...currentListing.images].sort((a, b) => {
      const aIndex = storeImagesOrder.indexOf(a.id?.uuid);
      const bIndex = storeImagesOrder.indexOf(b.id?.uuid);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  } else if (listingType === 'product-selling') {
    const productImagesOrder = currentListing.attributes?.publicData?.productImagesOrder || [];
    orderedImages = [...currentListing.images].sort((a, b) => {
      const aIndex = productImagesOrder.indexOf(a.id?.uuid);
      const bIndex = productImagesOrder.indexOf(b.id?.uuid);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  } else {
    const displayImagesOrder = currentListing?.attributes?.publicData?.displayImagesOrder || [];
    orderedImages = [...currentListing.images].sort((a, b) => {
      const aIndex = displayImagesOrder.indexOf(a.id?.uuid);
      const bIndex = displayImagesOrder.indexOf(b.id?.uuid);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }
  return orderedImages.length > 0 ? orderedImages[0] : null;
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isSearchPageCard,
    isProductCard,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { listingType, storeName, rentalSpaceListingIds, rentalSpacePriceRange } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage = getFirstImage(currentListing, listingType);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = isProductCard ? config.layout.productImage : config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const listingLink =
    listingType === "product-selling" ? "ProductListingPage"
      : listingType === "brand-listing" ? "BrandListingPage"
        : "ListingPage"

  console.log({ publicData })


  const getRentalPracesPricingRange = () => {
    if (rentalSpacePriceRange && rentalSpacePriceRange.amounts?.length === 1 && rentalSpacePriceRange.amounts[0]?.amount) {
      return priceData(new Money(rentalSpacePriceRange.amounts[0]?.amount, rentalSpacePriceRange.currency), config.currency, intl)?.formattedPrice
    }

    if (rentalSpacePriceRange && rentalSpacePriceRange.amounts?.length > 1 && rentalSpacePriceRange.amounts[0]?.amount) {
      const onlyAmountsList = rentalSpacePriceRange.amounts.map(a => a.amount);

      const minPrice = Math.min(...onlyAmountsList);
      const maxPrice = Math.max(...onlyAmountsList);

      return priceData(new Money(minPrice, rentalSpacePriceRange.currency), config.currency, intl)?.formattedPrice + " - " + priceData(new Money(maxPrice, rentalSpacePriceRange.currency), config.currency, intl)?.formattedPrice
    }

    return undefined;
  }

  const rentalPlacesPricingRage = getRentalPracesPricingRange();

  return (
    <NamedLink className={classes} name={listingLink} params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      {isSearchPageCard ?
        <div className={css.info}>
          {listingType === "store-listing" ?
            <div>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              {rentalSpaceListingIds &&
                <div className={css.title}>
                  <FormattedMessage id="ListingCard.numberOfDisplays" values={{ length: rentalSpaceListingIds?.length }} />
                </div>
              }

              {rentalPlacesPricingRage &&
                <div className={css.title}>
                  <FormattedMessage id="ListingCard.perDay" values={{ value: rentalPlacesPricingRage }} />
                </div>
              }

            </div>
            :
            <>
              <div className={css.title}>
                {richText(storeName, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            </>
          }
        </div>
        :
        <div className={css.info}>
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {showAuthorInfo ? (
              <div className={css.authorInfo}>
                <FormattedMessage id="ListingCard.author" values={{ authorName }} />
              </div>
            ) : null}
          </div>
        </div>
      }

    </NamedLink >
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
