import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { userTypes } from '../../util/userTypes';

const getSettingsTabs = (currentPage) => {
  return [
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];
}

const getClientTabs = (currentPage) => {
  return [
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];
}

const getBrandAndStoreTabs = (currentPage, approvedBrandProfile, approvedStoreProfile) => {
  return [
    {
      text: <FormattedMessage id="UserNav.addProduct" />,
      selected: currentPage === 'NewProductPage',
      disabled: !approvedBrandProfile,
      linkProps: {
        name: 'NewProductPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.brandsAndProducts" />,
      selected: currentPage === 'ManageListingsPage:brands-and-products',
      linkProps: {
        name: 'ManageBrandsAndProductsListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.shopsAndDisplays" />,
      selected: currentPage === 'ManageListingsPage:shops-and-displays',
      linkProps: {
        name: 'ManageShopsAndDisplaysListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.addDisplay" />,
      selected: currentPage === 'NewRentalSpacePage',
      disabled: !approvedStoreProfile,
      linkProps: {
        name: 'NewRentalSpacePage',
      },
    },
  ];
}

const UserNav = props => {
  const {
    className,
    rootClassName,
    currentPage,
    userType,
    approvedBrandProfile = true,
    approvedStoreProfile = true,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs =
    userType === userTypes.client
      ? getClientTabs(currentPage)
      : userType === userTypes.shopsAndBrands
        ? getBrandAndStoreTabs(currentPage, approvedBrandProfile, approvedStoreProfile)
        : getSettingsTabs(currentPage);

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
