/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React, { useState } from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldChip, ValidationError } from '..';

import css from './FieldMultipleItemsSelect.module.css';

const FieldMultipleItemsSelect = props => {
  const {
    className,
    rootClassName,
    label,
    id,
    fields,
    options,
    meta,
    smallChips,
    displayColumn,
  } = props;

  const classes = classNames(rootClassName || (displayColumn ? css.rootColumn : css.root), className);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = index => {
    setIsHovered(prevHoveredOptions => ({
      ...prevHoveredOptions,
      [index]: true, // Set isHovered to true for the hovered option
    }));
  };

  const handleMouseLeave = index => {
    setIsHovered(prevHoveredOptions => ({
      ...prevHoveredOptions,
      [index]: false, // Set isHovered to false for the unhovered option
    }));
  };

  return (
    <fieldset className={classes}>
      {label ? <label>{label}</label> : null}
      <div className="col">
        <div className={smallChips ? css.grid5Columns : css.grid4Columns}>
          {options.map((option, index) => {
            const fieldId = `${id}.${option.option}`;

            return (
              <div
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                key={fieldId}
              >
                <FieldChip
                  id={fieldId}
                  name={fields.name}
                  label={option.label}
                  value={option.option}
                  activeClassName={css.activeItem}
                  inactiveClassName={css.inactiveItem}
                  isHovered={isHovered[index]}
                />
              </div>
            );
          })}
        </div>
        <ValidationError fieldMeta={{ ...meta }} />
      </div>
    </fieldset>
  );
};

FieldMultipleItemsSelect.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

FieldMultipleItemsSelect.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      option: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const FieldMultipleItems = props => <FieldArray component={FieldMultipleItemsSelect} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldMultipleItems.propTypes = {
  name: string.isRequired,
};

export default FieldMultipleItems;
